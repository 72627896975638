:root
{
    --clr-bg: #f1f1f1;
    --clr-light: #fff;
    --clr-dark: #ddd;
    --clr-error: #f00;
    --clr-darker: rgb(73, 73, 73);
    --clr-interact: #345181;
}

body
{
    background-color: var(--clr-bg);
}

.header
{
    position: relative;

    display: flex;
    justify-content: center;
}

.logout
{
    font-size: .8em;
    font-weight: bold;

    position: absolute;
    right: 0;

    cursor: pointer;
}

.signator-logo
{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.signator-logo > img
{
    display: block;

    margin: 0;
}

.signator-logo > p
{
    font-size: .85em;
    font-weight: bold;

    margin: 0;

    color: var(--clr-interact);
}

.title
{
    font-size: 1em;
    font-weight: 800;

    text-transform: uppercase;

    color: var(--clr-darker);
}

.app
{
    width: calc(100vw - (20px * 2));
    /*height: calc(100vh - (20px * 2));*/
    padding: 20px;
}

@media (min-width: 800px)
{
    .app
    {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.app__container
{
    display: grid;

    width: 100%;
    max-width: 1024px;
    margin-right: auto;
    margin-left: auto;

    gap: 2em;
}

.app.app--starter .app__container
{
    grid-template-columns: 1fr 200px 1fr;
    grid-template-rows: 80px 1fr;
}

.app .preview
{
    padding: 1em;

    border: 2px solid var(--clr-dark);
    border-radius: 6px;
    background-color: var(--clr-light);
}


@media (min-width: 800px)
{
    .app:not(.app--starter) .app__container
    {
        /*min-height: 100vh;*/
        /*grid-template-columns: 300px 1fr;*/
        grid-template-areas: 'header header header header header'
        '. sidebar content content .'
        '. download client client .'
        'footer footer footer footer footer';
    }

    .app .sidebar
    {
        /*grid-column-start: 1;
        grid-column-end: 2;*/
        grid-area: sidebar;
    }

    .app .result
    {
        /*grid-column-start: 2;
        grid-column-end: -1;*/
        grid-area: content;
    }

    .app .download
    {
        grid-area: download;
    }

    .app .insert-signature
    {
        grid-area: client;
    }

    .header
    {
        grid-area: header;
    }
}


.app.app--starter .app__container > *
{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 2;;
}

.app.app--starter .header
{
    grid-row: 1;
}

.form-item
{
    display: flex;
    flex-direction: column;
}

.form-item .form-item__label
{
    font-size: .8em;
    font-weight: bold;

    display: block;

    margin-bottom: .25em;
}

.form-item + .form-item
{
    margin-top: .5em;
}

.form-item .form-item__input
{
    flex-grow: 1;

    padding: .5em 1em;

    border: 1px solid var(--clr-dark);
    border-radius: 3px;
    background-color: var(--clr-light);
}

.form-item.is-required .form-item__label::after
{
    content: ' *';

    color: var(--clr-error);
}

button
{
    font-weight: bold;

    margin-top: .5em;
    margin-bottom: .5em;
    padding: .5em 1em;
}

.email-client
{
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    max-width: 64px;

    cursor: pointer;
    text-decoration: none;
}

.email-client:focus,
.email-client:hover
{
    text-decoration: underline;
}

.email-client:not(.first-child)
{
    margin-left: 1em;
}

.email-client__icon
{
    width: 32px;
    height: 32px;
}

.email-client__name
{
    font-size: .65em;
    font-weight: bold;

    text-align: center;

    color: var(--clr-interact);
}

.overlayer.overlayer--instruction
{
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, .3);
}

.overlayer__inner
{
    position: relative;

    max-width: 600px;
    max-height: calc(100vh - 80px - 3em);
    margin: 1.5em auto;
    padding: 40px;

    border-radius: 6px;
    background-color: #fff;
}

.overlayer__content
{
    overflow-y: auto;

    max-width: 100%;
    max-height: calc(100vh - 80px - 3em);
    padding-right: 1em;
}

.overlayer__content img
{
    display: block;

    max-width: calc(100% - 1em);
    margin: .5em;
}

.overlayer__button
{
    font-weight: bold;

    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    cursor: pointer;

    border-radius: 50%;
}

.overlayer__button:focus,
.overlayer__button:hover
{
    background-color: #ddd;
}
